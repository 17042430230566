<!-- 
    This is independent module. It pushes number to dial to composible store, and sip module starts calling it if it is free
 -->
<template>
    <div class="text-end mt-2">
        Internal Numbers 
        <label class="switch s-outline s-outline-default mb-4 me-2">
            <input v-model="showInternalNumbers" type="checkbox" />
            <span class="slider round"></span>
            <label></label>
        </label> 
    </div>
    <vue-tel-input v-if="!showInternalNumbers"  :all-countries="customCountryList" v-model="phone" mode="national" :disabled="phoneStatus!='idle'" style="height:42px;z-index:1000" @on-input="phoneEntered"></vue-tel-input>
    
    
    <div class="select2" style="width:100%;height:50px" v-if="showInternalNumbers" >
        <div class="input-group">
            <span class="input-group-text" style="font-size:13px">To User:</span>    
            <multiselect 
                v-model="localDestinationNumber" 
                :options="internalPhonebook" 
                placeholder="Select User" 
                selected-label="" 
                select-label="" 
                :allow-empty="false" 
                :can-deselect="false"
                deselect-label="" 
                object="false" 
                track-by="number" 
                label="name" 
                style="height:30px"
                class="multiselect-blue form-control"
                :disabled="phoneStatus!='idle'"
            >
            </multiselect>
        </div>
    </div>



    <slot>
        
        <!-- This slot id for cancel/buy button -->
    </slot>
    <div class="btn-group-toggle row mb-4 mx-0  mt-5 ms-4" >
        <div class="text-color radio-primary col-12 ps-0 mb-3 custom-control custom-radio text-center" v-if="phoneStatus=='idle'" >
            <button type="button" class="btn btn-success btn-lg mb-2 me-2 rounded-circle"  :disabled="!dialButtonEnalbled"  @click="makeCall">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone-outgoing" data-v-5522efca=""><polyline points="23 7 23 1 17 1"></polyline><line x1="16" y1="8" x2="23" y2="1"></line><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
            </button>
        </div>
        <div class="text-color radio-primary col-12 ps-0 mb-3 custom-control custom-radio text-center" @click="cancel"  v-if="phoneStatus!='idle'">
            <button type="button" class="btn btn-danger btn-lg mb-2 me-2 rounded-circle">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone-off" data-v-5522efca=""><path d="M10.68 13.31a16 16 0 0 0 3.41 2.6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7 2 2 0 0 1 1.72 2v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.42 19.42 0 0 1-3.33-2.67m-2.67-3.34a19.79 19.79 0 0 1-3.07-8.63A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91"></path><line x1="23" y1="1" x2="1" y2="23"></line></svg>
            </button>
        </div>
    </div>
    
</template>

<script setup>

    import { VueTelInput } from 'vue-tel-input';
    import 'vue-tel-input/vue-tel-input.css';

    import Multiselect from '@suadelabs/vue3-multiselect';
    import '@suadelabs/vue3-multiselect/dist/vue3-multiselect.css';
    import '~/assets/css/select2.css';

    import { ref, getCurrentInstance } from 'vue';
    const instance = getCurrentInstance();
    const { $get } = useNuxtApp();
    const userData = useMyData();

    const props = defineProps({
        modelValue: { type: String, default: '' },

        // onClick: Function
    });
    const phone = ref(null)
    const phoneStatus = usePhoneStatus(); // idle or not

    watch (() => phoneStatus.value, async (newValue) => {
        if (newValue == 'idle') {
            phone.value = "";
        }
    });
    
    const localDestinationNumber = ref(null);
    const isValid = ref(false);
    const fullPhoneNumber = ref();
    const showInternalNumbers = ref(false)
    const dialCommand = useDialCommand();

    

    const phoneEntered = (number, object) => {
        isValid.value = object.valid;
        if (object.valid == true){
            fullPhoneNumber.value = object.number;
            instance.emit('update:modelValue', object.number);
        }
    }
    const internalPhonebook = ref([]);
    watch (() => userData.value.agentId, async (newValue, oldValue) => {
        if (typeof oldValue === 'undefined') {
            let current_sip_account_id = userData.value.agentId;
            if (userData.value.agentId){;
                const response2 = await $get('/api/protected/agent/account');
                for (let item of response2.data) {
                    let object = {};
                    object.name = `${item.firstname} ${item.lastname} (${item.extension})`;
                    object.number = item.extension;
                    if (item.sip_account_id != current_sip_account_id){
                        internalPhonebook.value.push(object);
                    }
                }
            }

        }
    })
    

    const dialButtonEnalbled = computed(() => {
        let result = false;
        if (showInternalNumbers.value == true && localDestinationNumber.value) result = true;
        if (showInternalNumbers.value == false && isValid.value) result = true;
        if (phoneStatus.value =='dialing' ) return true;
        return result;
        
    })


    const makeCall = () => {
        
        let options = {
            source: "dialer",
            direction: "outbound",
            directive: "start",
            type: showInternalNumbers.value ? "internal" : "external", // internal, external
            number: showInternalNumbers.value ? localDestinationNumber.value.number : fullPhoneNumber.value,
            name: showInternalNumbers.value ? localDestinationNumber.value.name : phone.value // this field may be used for customer name
        }
        dialCommand.value = options;

        
    }
    const cancel = () => {
        let options = {
            source: "dialer",
            direction: "outbound",
            directive: "stop",
        }
        
        dialCommand.value = options;
    }

    const customCountryList = [
        { name: 'United States', iso2: 'US', dialCode: '1' },
        { name: 'Canada', iso2: 'CA', dialCode: '1' },
        // { name: 'Germany', iso2: 'de', dialCode: '49' },
        // { name: 'India', iso2: 'in', dialCode: '91' },
    ];

</script>

<style scoped>
    .vti__input { height: 54px; font-size:14px;}
    ::v-deep(.multiselect__tags) { border: 1px solid #888ea8;font-size: 14px;}
    ::v-deep(.multiselect, .multiselect__input, .multiselect__single) {font-size: 14px;font-color:white;}
    ::v-deep(.multiselect__placeholder) {padding-left: 14px;}
</style>