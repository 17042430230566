<template>
    <div class="btn-group-toggle row mt-5 mb-4 mx-0">
        <div v-if="agentStatus == 'Logged Out' || agentStatus == 'On Break'" class="text-color radio-primary col-12 ps-0 mb-3 custom-control custom-radio">
            <input type="radio" id=login class="custom-control-input" @click="cc_login">
            <label class="custom-control-label" style="background-color: #00964954;"  for="login">Enter Call Center Mode</label>
        </div>
        <div v-if="agentStatus == 'Available'" class="text-color radio-primary col-12 ps-0 mb-3 custom-control custom-radio">
            <input type="radio" id="logout"  class="custom-control-input" @click="cc_logout" >
            <label class="custom-control-label" style="background-color: #ff49002e;" for="logout">Exit Call Center Mode</label>
        </div>
    </div>
    
</template>

<script setup>
    const {$patch} = useNuxtApp();
    const agentStatus = useAgentStatus();
    const user = useMyData();
    const {userMessages} = useUserWebSocket();


    const cc_login = async function(){
        await $patch('/api/protected/agent/status', {status:"Available"});
    }
    const cc_logout = async function(){
        await $patch('/api/protected/agent/status', {status:"Logged Out"});
    }

    watch (() => userMessages.value, async (newValue) => {
        if (JSON.parse(newValue).app == 'agent-status'){
            agentStatus.value = JSON.parse(newValue).data.status;
        }
    });

    watch (() => user.value, async (newValue) => {
        agentStatus.value = newValue.ccStatus;
    },{deep:true, immediate:true});
</script>